import React, { useState, useEffect } from "react";
import { Text, View, Linking } from "react-native";
import { Router, Switch, Route, Redirect } from "./routing";
import { getUser } from "./services/user";
import Login from "./pages/login";
import AddEvent from "./pages/add-event.js";
import AddGuest from "./pages/add-guest.js";
import GuestList from "./pages/guest_list";
import QRCodeGenerator from "./pages/qr-code-generator";
import EventsPage from "./pages/events";
import KalkulatorTamu from "./pages/kalkulator-tamu-without-event";

const App = () => {
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const isUserLoggedIn = async () => {
      setLoading(true);
      const user = await getUser(setUser, setError);
      setLoading(false);
    };
    isUserLoggedIn();
  }, []);

  if (isLoading) {
    return (
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <Text>Loading..</Text>
      </View>
    );
  }

  return (
    <View>
      <Router>
        <Switch>
          <Route exact path="/guest-list" render={(props) => <GuestList />} />
          <Route exact path="/login" render={(props) => <Login />} />
          <Route exact path="/add-event" render={(props) => <AddEvent />} />
          <Route exact path="/add-guest" render={(props) => <AddGuest />} />
          <Route exact path="/events" render={(props) => <EventsPage />} />
          <Route exact path="/" render={(props) => <KalkulatorTamu />} />
          <Route
            exact
            path="/qrcode-generator"
            render={(props) => <QRCodeGenerator />}
          />
          {/* {user === undefined ||
            (user === null && (
              <View>
                <Redirect
                  to={{
                    pathname: "/login",
                  }}
                />
              </View>
            ))} */}
        </Switch>
      </Router>
    </View>
  );
};

export default App;
