import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import firebaseapp from "./firebase";

export const getUserAsync = async () => {
  const auth = getAuth();
  const persistence = await setPersistence(auth, browserLocalPersistence);
  return auth.currentUser;
};

export const getUser = async (setUser, setError) => {
  try {
    const userInfo = await getUserAsync();
    if (userInfo !== null) {
      return setUser(userInfo);
    }
  } catch (error) {
    return setError(error);
  }
};

export const googleSignIn = async (setUser, setError) => {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  const persistence = await setPersistence(auth, browserLocalPersistence);
  try {
    const result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    return setUser(user);
  } catch (error) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    return setError(error);
  }
};

export const signOut = async (setUser, setError) => {
  try {
    const auth = getAuth();
    await auth.signOut();
    return await setUser(null);
  } catch (error) {
    return await setError(error);
  }
};
