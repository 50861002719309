import { firestore } from "./firebase";
import { getUserAsync } from "./user";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  addDoc,
  getDoc,
} from "firebase/firestore";

const db = firestore();

export const EVENTS = "events";
export const getEventsCollection = () => {
  return collection(db, EVENTS);
};

export const getEventIdDoc = (eventId) => {
  const path = `/${eventId}`;
  return doc(getEventsCollection(), path);
};

export const eventBase = {
  data: [],
  limit: 10,
  page: 0,
};

export const getEventsByUser = async (page = 0, limit = 10) => {
  const { uid, email } = await getUserAsync();
  const filter = where(`access.${uid}`, "==", true);
  const q = query(getEventsCollection(), filter);
  const snapshot = await getDocs(q);

  const result = eventBase;
  result.data = [];
  result.page = page;
  result.limit = limit;

  if (snapshot.empty) {
    return result;
  }
  snapshot.forEach((doc) => {
    let data = doc.data();
    data.id = doc.id;
    result.data.push(data);
  });

  return result;
};

export const getEventById = async (eventId) => {
  const doc = getEventIdDoc(eventId);
  const snapshot = await getDoc(doc);

  if (!snapshot.exists()) {
    return null;
  }

  let data = snapshot.data();
  data.id = snapshot.id;
  return data;
};

export const createEventByUser = async (data) => {
  const user = await getUserAsync();
  const { uid, email } = user;

  const payload = {
    access: {},
    accessByEmail: {},
    name: data.name,
    link: data.link,
    city: data.city,
    event_date: data.event_date,
    created_at: Math.floor(new Date() / 1000),
    modified_at: null,
  };

  // set access
  payload.access[uid] = true;
  payload.accessByEmail[email] = true;

  return await addDoc(getEventsCollection(), payload);
};
