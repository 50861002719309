import React from "react";
import { Text, View } from "react-native";
import dayjs from "dayjs";

const Footer = () => {
  const now = dayjs();
  return (
    <View>
      <View style={{ height: 20 }} />
      <View style={{ alignItems: "center", paddingBottom: 20 }}>
        <Text
          style={{
            fontFamily: "Rubik",
            fontWeight: 500,
          }}
        >
          Copyright © Brawn Website {now.year()}
        </Text>
      </View>
    </View>
  );
};

export default Footer;
