import React, { useState, useEffect } from "react";
import {
  SafeAreaView,
  View,
  FlatList,
  StyleSheet,
  Text,
  StatusBar,
  Platform,
  Button,
} from "react-native";
import { eventItem as renderGuestItem } from "../components/guests/item";
import BackButton from "../components/shared/button-back";
import { getUser, googleSignIn, signOut } from "../services/user";
import { getEventById } from "../services/event";
import { getGuestsByEventId } from "../services/guest";
import { useHistory, useQuery } from "../routing";
import Header from "../components/header";

const Item = ({ title }) => (
  <View style={styles.item}>
    <Text style={styles.title}>{title}</Text>
  </View>
);

const App = () => {
  const history = useHistory();
  const query = useQuery();
  const eventId = query.get("eventId");

  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [event, setEvent] = useState(null);
  const [error, setError] = useState(null);
  const [guests, setGuests] = useState([]);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      getUser(setUser, setError);
      let event = await getEventById(eventId);
      await setEvent(event);
      let result = await getGuestsByEventId(eventId);
      await setGuests(result.data);
      await setLoading(false);
    };
    return init();
  }, []);

  if (isLoading) {
    return (
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <Text>Loading..</Text>
      </View>
    );
  }

  const guestItem = ({ item, index }) => {
    return renderGuestItem(item, index);
  };

  const guestList = (
    <FlatList
      data={guests}
      renderItem={guestItem}
      keyExtractor={(item) => item.id}
      ItemSeparatorComponent={() => {
        return <View style={styles.space} />;
      }}
    />
  );

  return (
    <SafeAreaView style={styles.fullHeight}>
      <Header />
      <View style={styles.container}>
        <View>
          <Text style={styles.title}>Data Tamu {event.name}</Text>
        </View>
        <View style={styles.listWrapper}>
          <Text style={styles.numberText}>No.</Text>
          <Text style={styles.numberText}>Nama</Text>
          <Text style={styles.rowText}>No. Whatsapp</Text>
          <Text style={styles.rowText}>Email</Text>
          <Text style={styles.rowText}>Share on</Text>
        </View>
        <View>{guestList}</View>
        <View style={{ width: 100, height: 100 }} />
        <View style={{ width: 100, height: 50, marginLeft: 25 }}>
          {user === null ? (
            <Button
              onPress={(_) => googleSignIn(setUser, setError)}
              title="Login"
            />
          ) : (
            <Button
              onPress={(_) => signOut(setUser, setError)}
              title="SignOut"
            />
          )}
        </View>
        <BackButton history={history} />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 1000,
    marginTop: StatusBar.currentHeight || 0,
  },
  fullHeight: {
    height: Platform.OS === "web" ? "100vh" : "100%",
    marginTop: 20,
  },
  item: {
    backgroundColor: "#f9c2ff",
    padding: 5,
    marginVertical: 8,
    marginHorizontal: 16,
  },
  title: {
    fontSize: 32,
    textAlign: "center",
  },
  space: {
    width: 50, // or whatever size you need
    height: 20,
  },
  listWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginLeft: 10,
  },
  rowText: {
    color: "#000",
    width: 250,
    fontSize: 20,
    padding: 5,
  },
  numberText: {
    color: "#000",
    flex: 1,
    marginLeft: 20,
    fontSize: 20,
    padding: 5,
  },
});

export default App;
