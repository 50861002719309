import React, { useEffect, useReducer, useState } from "react";
import {
  Text,
  TextInput,
  View,
  StyleSheet,
  Platform,
  Alert,
} from "react-native";
import { Button } from "react-native-paper";
import DateTimePicker from "react-datetime-picker";
import dayjs from "dayjs";
import Header from "../components/header";
import { useHistory } from "../routing";

import { createEventByUser, getEventsByUser } from "../services/event";

const AddEvent = () => {
  const history = useHistory();

  const [eventName, setEventName] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [eventCity, setEventCity] = useState("");
  const [eventDateValue, setEventDateValue] = useState(new Date());

  const createSampleEvent = () => {
    createEventByUser({
      name: "some name",
      city: "bandung",
      event_date: new Date(),
    });
  };

  const link =
    "https://elinvi.brawn.id/undangan-digital-tema-cream/mengundang=";

  useEffect(() => {}, []);

  const eventDate = dayjs(eventDateValue).format("DD-MM-YYYY h:mm:ss a");

  const generateOutput = () => {
    return (
      <View>
        <View>
          <Text style={styles.pageTitle}>Berhasil dibuat</Text>
        </View>
        <View>
          <Text style={styles.textStyle}>Nama Event: {eventName},</Text>
          <Text style={styles.textStyle}>Kota: {eventCity},</Text>
          <Text style={styles.textStyle}>Tanggal dan Jam: {eventDate}.</Text>
        </View>
      </View>
    );
  };

  const eventsUrl = `/events`;
  const linkPlaceholder = `contoh: "example.com"`;

  return (
    <View style={styles.fullHeight}>
      <Header />
      <View style={styles.container}>
        <View>
          <Text style={styles.pageTitle}>Tambahkan Event</Text>
        </View>
        <View style={styles.textContainer}>
          <Text style={{ width: 170, fontSize: 20 }}>Nama Event</Text>
          <Text style={{ width: 10, fontSize: 20 }}>:</Text>
          <TextInput
            style={styles.input}
            placeholder="Nama Event"
            onChangeText={(eventName) => setEventName(eventName)}
            defaultValue={eventName}
          />
        </View>
        <View style={styles.textContainer}>
          <Text style={{ width: 170, fontSize: 20 }}>Event Link</Text>
          <Text style={{ width: 10, fontSize: 20 }}>:</Text>
          <TextInput
            style={styles.input}
            placeholder={linkPlaceholder}
            onChangeText={(eventLink) => setEventLink(eventLink)}
            defaultValue={eventLink}
          />
        </View>
        <View style={styles.textContainer}>
          <Text style={{ width: 170, fontSize: 20 }}>Kota</Text>
          <Text style={styles.textStyle}>:</Text>
          <TextInput
            style={styles.input}
            placeholder="Kota"
            onChangeText={(eventCity) => setEventCity(eventCity)}
            defaultValue={eventCity}
          />
        </View>
        <View style={styles.textContainer}>
          <Text style={{ width: 170, fontSize: 20 }}>Tanggal</Text>
          <Text style={{ width: 10, fontSize: 20 }}>:</Text>
          <DateTimePicker
            style={styles.input}
            format="dd-MM-y h:mm:ss a"
            onChange={setEventDateValue}
            value={eventDateValue}
          />
        </View>
        <View style={styles.space} />
        <View style={{ width: 150 }}>
          <Button
            mode="contained"
            color="blue"
            onPress={async () => {
              try {
                await createEventByUser({
                  name: eventName,
                  link: eventLink,
                  city: eventCity,
                  event_date: Math.floor(eventDateValue / 1000),
                });

                history.push(eventsUrl);
              } catch (error) {
                console.log(error);
                alert(error);
              }
            }}
          >
            tambah
          </Button>
        </View>
        <View style={styles.space} />
        <View>{getEventsByUser}</View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 1000,
    marginTop: 20,
    marginLeft: 20,
  },
  textContainer: {
    width: 500,
    marginTop: 20,
    flexDirection: "row",
    alignContent: "center",
  },
  textStyle: {
    fontSize: 20,
  },
  pageTitle: {
    fontSize: 40,
    fontWeight: "500",
  },
  input: {
    height: 40,
    width: 300,
    margin: 3,
    borderWidth: 1,
    padding: 10,
    alignSelf: "center",
    alignContent: "center",
  },
  space: {
    width: 1000,
    height: 50,
  },
  fullHeight: {
    height: Platform.OS === "web" ? "100vh" : "100%",
  },
});

export default AddEvent;
