import React from "react";
import { Text, TextInput, View, StyleSheet } from "react-native";
import { Button } from "react-native-paper";
import { getGuestsByEventId } from "../services/guest";
import QRCode from "react-qr-code";
import { useQuery } from "../routing.web";

const QRCodeGenerator = (guest) => {
  const query = useQuery();
  const guestId = query.get("guestId");
  const fullName = query.get("fullName");

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{fullName}</Text>
      <QRCode value={guestId} size={500} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 1000,
    marginTop: 20,
    marginLeft: 20,
  },
  title: {
    fontSize: 32,
  },
});

export default QRCodeGenerator;
