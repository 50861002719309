import React, {useEffect, useState} from "react";
import {
    Text,
    TextInput,
    View,
    Platform,
    StyleSheet,
    Image,
    TouchableOpacity,
    useWindowDimensions,
    Dimensions,
    ScrollView
} from "react-native";
import {useQuery} from "../routing";
import {toTitleCase} from "../util";
import {messageTemplate} from "../constants/message-template";
import {getEventById} from "../services/event";
import Handlebars from "handlebars";
import dayjs from "dayjs";
import locale_id from "dayjs/locale/id";
import Footer from "../components/footer";
import Clipboard from "@react-native-clipboard/clipboard";
import TextInputWithError from "../components/shared/textinput-with-error";
import AutoExpandingTextInput from "../components/const/multilinetextinput";

const NewGuest = (guest) => {
    const query = useQuery();
    const eventId = query.get("eventid");

    const templateStr = (eventId in messageTemplate ? messageTemplate[eventId] : messageTemplate["default"]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [guest_name, setGuestName] = useState("");
    const [message, onChangeMessage] = useState(templateStr);
    const [copiedText, setCopiedText] = useState("");
    const [copiedMessage, setCopiedMessage] = useState("");

    const fetchCopiedText = async () => {
        const text = await Clipboard.getString();
        setCopiedText(text);
    };

    const subject = `Invitation`;
    let messageToSend = "";
    if (message !== null) {
        try {
            const template = Handlebars.compile(message);
            messageToSend = template({guest_name: guest_name, guest_name_tc: toTitleCase(guest_name), encoded_guest_name: encodeURI(guest_name)});
        } catch (error) {
            console.log(error);
        }
    }

    const mailMessage = encodeURIComponent(messageToSend);
    const mailto = `mailto:?subject=` + subject + "&body=" + mailMessage;
    const shareWaUrl = `https://wa.me/?text=` + mailMessage;

    let numOfLinesCompany = 12;

    const copyToClipboard = () => {
        Clipboard.setString(messageToSend);
    };

    return (<View style={
        styles.fullHeight
    }>
        <View style={
            {alignItems: "center"}
        }>
            <View style={
                {
                    height: isSmallDevice ? 10 : 15
                }
            }/>
            <TouchableOpacity>
                <Image source={
                        require("../assets/Logo-elinvi-Undangan.png")
                    }
                    style={
                        {
                            width: 90,
                            height: 90
                        }
                    }
                    resizeMode="cover"/>
                <View style={
                    isSmallDevice ? {
                        width: 5
                    } : {
                        width: 20
                    }
                }/>
            </TouchableOpacity>
            <View style={
                {
                    height: isSmallDevice ? 20 : 25
                }
            }/>
            <View>
                <Text style={
                    styles.pageTitle
                }>Kirim Undangan Untuk Para Tamu</Text>
            </View>
            <View style={
                {alignItems: "center"}
            }>
                <Text style={
                    styles.subTitle
                }>
                    Bagikan undanganmu kepada keluarga, kerabat, dan teman melalui
                                Generator link di bawah ini :
                </Text>
            </View>

            <View style={
                {height: 20}
            }/>

            <View style={
                {
                    width: isSmallDevice ? "90%" : "40%",
                    height: isSmallDevice ? "100%" : "100%",
                    borderWidth: 2,
                    borderColor: "#008080",
                    borderRadius: 9,
                    alignItems: "center",
                    backgroundColor: "#d5f1f0"
                }
            }>
                <View style={
                    styles.textContainer
                }>
                    <Text style={
                        isSmallDevice ? {
                            fontFamily: "Rubik",
                            width: 110,
                            fontSize: 14,
                            fontWeight: "700"
                        } : {
                            fontFamily: "Rubik",
                            width: 130,
                            fontSize: 20,
                            fontWeight: "700"
                        }
                    }>
                        Nama Tamu *
                    </Text>
                </View>
                <View style={
                    styles.textContainer
                }>
                    <Text style={
                        {
                            fontFamily: "Rubik",
                            fontSize: isSmallDevice ? 12 : 15
                        }
                    }>
                        (Hanya teks, tidak boleh menggunakan symbol &, -, +, dan
                                      sebagainya)
                    </Text>
                    <Text style={
                        {flex: 1}
                    }/>
                </View>
                <TextInputWithError containerStype={
                        styles.input
                    }
                    style={
                        styles.input
                    }
                    placeholder=""
                    onChangeText={
                        (guest_name) => setGuestName(guest_name)
                    }
                    defaultValue={guest_name}/>
                <View style={
                    styles.textContainer
                }>
                    <Text style={
                        isSmallDevice ? {
                            fontFamily: "Rubik",
                            width: 110,
                            fontSize: 14,
                            fontWeight: "700"
                        } : {
                            fontFamily: "Rubik",
                            width: 120,
                            fontSize: 20,
                            fontWeight: "700"
                        }
                    }>
                        Pesan *
                    </Text>
                </View>

                <AutoExpandingTextInput 
                    value={messageToSend}
                    editable={false}
                    // onChangeText={
                    //     (text) => onChangeMessage(text)
                    // }
                    />

                <View style={
                    {height: 40}
                }/>
            </View>

            <View style={
                {
                    height: isSmallDevice ? 20 : 40
                }
            }/>
            <View style={
                {
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }
            }>
                <View style={
                    isSmallDevice ? {
                        flex: 1.2
                    } : {
                        width: 200
                    }
                }>
                    <a href={shareWaUrl}
                        target="_blank">
                        <TouchableOpacity style={
                            styles.buttonWa
                        }>
                            <Image source={
                                    require("../assets/share-variant.png")
                                }
                                style={
                                    {
                                        width: 20,
                                        height: 20
                                    }
                                }
                                resizeMode="cover"/>
                            <View style={
                                isSmallDevice ? {
                                    width: 5
                                } : {
                                    width: 20
                                }
                            }/>
                            <Text style={
                                styles.textButton
                            }>Share Via Whatsapp</Text>
                        </TouchableOpacity>
                    </a>
                </View>
                <View style={
                    {height: 20}
                }/> {
                copiedMessage != "" && (<Text style={
                    {
                        position: "absolute",
                        zIndex: 2,
                        color: "white",
                        backgroundColor: "black",
                        textAlign: "center",
                        fontSize: isSmallDevice ? 12 : 15,
                        width: "100%",
                        height: 30,
                        bottom: 20,
                        borderRadius: 4
                    }
                }> {copiedMessage} </Text>)
            }
                <View style={
                    isSmallDevice ? {
                        flex: 1
                    } : {
                        width: 150
                    }
                }>
                    <TouchableOpacity style={
                            styles.buttonEmail
                        }
                        onPress={
                            (_) => {
                                if (copiedMessage == "") {
                                    setCopiedMessage("Message copied to clipboard!");
                                    setTimeout((_) => setCopiedMessage(""), 4000);
                                }
                            }
                    }>
                        <View style={
                            isSmallDevice ? {
                                width: 39
                            } : {
                                width: 2
                            }
                        }/>
                        <Image source={
                                require("../assets/content-copy.png")
                            }
                            style={
                                {
                                    width: 20,
                                    height: 20
                                }
                            }
                            resizeMode="cover"/>
                        <View style={
                            isSmallDevice ? {
                                width: 5
                            } : {
                                width: 20
                            }
                        }/>
                        <Text style={
                                styles.textButton
                            }
                            onPress={copyToClipboard}>
                            Copy Text
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
            <Footer/>
        </View>
    </View>);
};

const isSmallDevice = Dimensions.get("window").width < 768;

const styles = StyleSheet.create({
    fullHeight: {
        height: "100%"
    },
    scrollView: {
        backgroundColor: "#FFFFFF",
        marginHorizontal: 20
    },
    buttonWa: isSmallDevice ? {
        alignItems: "center",
        backgroundColor: "#61CE70",
        padding: 10,
        borderRadius: 9,
        flexDirection: "row"
    } : {
        alignItems: "center",
        backgroundColor: "#61CE70",
        padding: 10,
        borderRadius: 9,
        flexDirection: "row"
    },
    buttonEmail: isSmallDevice ? {
        alignItems: "center",
        backgroundColor: "#1579e6",
        padding: 10,
        borderRadius: 9,
        flexDirection: "row",
        width: 176,
        height: 42,
        zIndex: 1
    } : {
        alignItems: "center",
        backgroundColor: "#1579e6",
        padding: 10,
        borderRadius: 9,
        flexDirection: "row",
        zIndex: 1
    },
    textButton: {
        color: "#fff",
        fontSize: isSmallDevice ? 14 : 15,
        fontWeight: "500",
        fontFamily: "Rubik"
    },
    textContainer: {
        marginTop: 20,
        flexDirection: "row",
        alignContent: "center",
        textAlign: "center"
    },
    pageTitle: isSmallDevice ? {
        fontSize: 18,
        fontWeight: "700",
        color: "#008080",
        fontFamily: "Rubik",
        lineHeight: 21,
        letterSpacing: "0em"
    } : {
        fontSize: 25,
        fontWeight: "500",
        fontFamily: "Rubik",
        color: "#008080"
    },
    subTitle: {
        fontFamily: "Rubik",
        textAlign: "center",
        width: isSmallDevice ? "80%" : "80%",
        fontSize: isSmallDevice ? 14 : 17
    },
    input: isSmallDevice ? {
        fontFamily: "Rubik",
        backgroundColor: "#FFFFFF",
        justifyContent: "center",
        textAlign: "center",
        color: "#000",
        width: "90%",
        height: 43,
        borderRadius: 9,
        fontSize: 20,
        marginTop: 20
    } : {
        fontFamily: "Rubik",
        backgroundColor: "#FFFFFF",
        justifyContent: "center",
        textAlign: "center",
        color: "#000",
        width: "90%",
        height: 60,
        borderRadius: 9,
        fontSize: 20,
        marginTop: 20
    },
    message: isSmallDevice ? {
        fontFamily: "Rubik",
        backgroundColor: "#FFFFFF",
        width: "90%",
        height: "100%",
        borderRadius: 5,
        justifyContent: "center",
        textAlign: "left",
        color: "#000",
        fontSize: 14,
        marginTop: 20
    } : {
        fontFamily: "Rubik",
        backgroundColor: "#FFFFFF",
        width: "90%",
        height: 300,
        borderRadius: 9,
        justifyContent: "center",
        textAlign: "left",
        color: "#000",
        fontSize: 16,
        marginTop: 20
    }
});

export default NewGuest;
