import React from "react";
import { View, Text, TextInput } from "react-native";

const TextInputWithError = (props) => {
  let errorTextStyle = {};
  if (props.error != undefined) {
    errorTextStyle = { borderRadius: 10, borderWidth: 1, borderColor: "red" };
  }
  return (
    <View
      style={[
        props.containerStyle,
        { width: "100%", alignItems: "center", justifyContent: "center" },
        errorTextStyle,
      ]}
    >
      <TextInput style={props.style} {...props} />
      <Text style={{ color: "red" }}>{props.error}</Text>
    </View>
  );
};

export default TextInputWithError;
