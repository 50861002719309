import React, { useState } from "react";
import {
  SafeAreaView,
  View,
  FlatList,
  StyleSheet,
  Text,
  StatusBar,
} from "react-native";
import Navigator from "./navigator";

const App = () => {
  return <Navigator />;
};

export default App;
