import { useLocation } from "react-router-dom";
export function useQuery() {
 return new URLSearchParams(useLocation().search);
}

export {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useHistory,
} from "react-router-dom";
