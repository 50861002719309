import React from "react";
import { TextInput, Dimensions, StyleSheet } from "react-native";
import TextInputWithError from "../shared/textinput-with-error";
class AutoExpandingTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      height: 0,
    };
  }

  render() {
    return (
      <TextInputWithError
        {...this.props}
        multiline={true}
        // onChangeText={(text) => {
        //   this.setState({ text });
        // }}
        onContentSizeChange={(event) => {
          this.setState({ height: event.nativeEvent.contentSize.height });
        }}
        style={[
          styles.message,
          { height: Math.max(35, this.state.height), padding: 10 },
        ]}
        // value={this.state.text}
      />
    );
  }
}
const isSmallDevice = Dimensions.get("window").width < 768;
const styles = StyleSheet.create({
  message: isSmallDevice
    ? {
        backgroundColor: "#FFFFFF",
        width: "90%",
        height: "100%",
        borderRadius: 5,
        justifyContent: "center",
        textAlign: "left",
        color: "#000",
        fontSize: 14,
        marginTop: 20,
      }
    : {
        backgroundColor: "#FFFFFF",
        width: "90%",
        height: 300,
        borderRadius: 9,
        justifyContent: "center",
        textAlign: "left",
        color: "#000",
        fontSize: 16,
        marginTop: 20,
      },
});

export default AutoExpandingTextInput;
