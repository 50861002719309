import React, { useState } from "react";
import { Text, TextInput, View, Platform, StyleSheet } from "react-native";
import { Button } from "react-native-paper";
import { createGuestByEventId } from "../services/guest";
import BackButton from "../components/shared/button-back";
import QRCode from "react-qr-code";
import { useLinkTo } from "@react-navigation/native";
import { useHistory, useQuery } from "../routing";
import Header from "../components/header";

const AddGuest = () => {
  const [first_name, setFirstName] = useState("");
  const [family_name, setFamilyName] = useState("");
  const [wa_number, setWANumber] = useState("");
  const [email, setEmail] = useState("");

  const history = useHistory();
  const query = useQuery();
  const eventId = query.get("eventId");

  const eventsUrl = `/guest-list?eventId=${eventId}`;

  return (
    <View style={styles.fullHeight}>
      <Header />
      <View style={{ marginLeft: 20 }}>
        <View>
          <Text style={styles.pageTitle}>Tambahkan Tamu</Text>
        </View>
        {/* <View style={styles.textContainer}>
        <Text style={{ flex: 1, fontSize: 20 }}>Event</Text>
        <Text style={{ width: 10, fontSize: 20 }}>:</Text>
        <Text>{Item.name}</Text>
      </View> */}
        <View style={styles.textContainer}>
          <Text style={{ flex: 1, fontSize: 20 }}>Nama Depan</Text>
          <Text style={{ width: 10, fontSize: 20 }}>:</Text>
          <TextInput
            style={styles.input}
            placeholder="Nama Depan"
            onChangeText={(first_name) => setFirstName(first_name)}
            defaultValue={first_name}
          />
        </View>
        <View style={styles.textContainer}>
          <Text style={{ flex: 1, fontSize: 20 }}>Nama Keluarga</Text>
          <Text style={styles.textStyle}>:</Text>
          <TextInput
            style={styles.input}
            placeholder="Nama Belakang"
            onChangeText={(family_name) => setFamilyName(family_name)}
            defaultValue={family_name}
          />
        </View>
        <View style={styles.textContainer}>
          <Text style={{ flex: 1, fontSize: 20 }}>No. Whatsapp</Text>
          <Text style={styles.textStyle}>:</Text>
          <TextInput
            style={styles.input}
            placeholder="No. WA"
            onChangeText={(wa_number) => setWANumber(wa_number)}
            defaultValue={wa_number}
          />
        </View>
        <View style={styles.textContainer}>
          <Text style={{ flex: 1, fontSize: 20 }}>Email</Text>
          <Text style={styles.textStyle}>:</Text>
          <TextInput
            style={styles.input}
            placeholder="e-mail"
            onChangeText={(email) => setEmail(email)}
            defaultValue={email}
          />
        </View>
        <View style={styles.space} />
        <View style={{ width: 150 }}>
          <Button
            mode="contained"
            color="blue"
            onPress={async () => {
              try {
                const guestId = await createGuestByEventId(eventId, {
                  first_name: first_name,
                  family_name: family_name,
                  wa_number: wa_number,
                  email: email,
                });
                history.push(eventsUrl);
              } catch (error) {
                console.log(error);
                console.log(eventId);
                alert(error);
              }
            }}
          >
            tambah
          </Button>
          <View style={{ height: 20 }} />
          <BackButton history={history} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 1000,
    marginTop: 20,
    marginLeft: 20,
  },
  fullHeight: {
    height: Platform.OS === "web" ? "100vh" : "100%",
    // marginLeft: 50,
    marginTop: 20,
  },
  textContainer: {
    width: 500,
    marginTop: 20,
    flexDirection: "row",
    alignContent: "center",
  },
  textStyle: {
    fontSize: 20,
  },
  pageTitle: {
    fontSize: 40,
    fontWeight: "500",
  },
  input: {
    height: 40,
    width: 300,
    margin: 3,
    borderWidth: 1,
    padding: 10,
    alignSelf: "center",
    alignContent: "center",
  },
  space: {
    width: 1000,
    height: 100,
  },
});

export default AddGuest;
