import React, { useState, useEffect } from "react";
import {
  Text,
  Image,
  View,
  Button,
  Platform,
  StyleSheet,
  Dimensions,
} from "react-native";
import { getUser, googleSignIn, signOut } from "../../services/user";

const isSmallDevice = Dimensions.get("window").width < 768;

const Header = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [guests, setGuests] = useState([]);

  const getData = async () => {
    try {
      await getUser(setUser, setError);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    return getData();
  }, []);

  const homeUrl = `/`;

  return (
    <View style={styles.header}>
      <View style={{ flex: 1, marginLeft: 20, alignSelf: "center" }}>
        <a href={homeUrl}>
          <Image
            source={require("../../assets/elinvi-icon.png")}
            style={
              isSmallDevice
                ? { width: 50, height: 50 }
                : { width: 70, height: 70 }
            }
            resizeMode="cover"
          />
        </a>
      </View>
      {user !== null && (
        <View
          style={
            isSmallDevice
              ? { flex: 1.9, alignSelf: "center" }
              : { width: 200, alignSelf: "center" }
          }
        >
          <Text style={styles.userText}>
            {user.displayName + "\n" + user.email}
          </Text>
        </View>
      )}
      {/* <Login /> */}
      <View style={styles.button}>
        {user === null ? (
          <Button
            onPress={(_) => googleSignIn(setUser, setError)}
            title="Login"
            color="#4686C8"
          />
        ) : (
          <Button
            onPress={(_) => signOut(setUser, setError)}
            title="SignOut"
            color="#4686C8"
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    height: isSmallDevice ? "10%" : "10%",
    backgroundColor: "#4686C8",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
  },
  button: {
    width: 100,
    alignSelf: "center",
    marginRight: 20,
  },
  userText: {
    color: "white",
    fontSize: isSmallDevice ? 12 : 15,
  },
});

export default Header;
