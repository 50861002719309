import React, { useCallback } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  Alert,
  Linking,
  Button,
} from "react-native";

import { useQuery } from "../../routing.web";

const styles = new StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
  },
  baseText: {
    color: "#000",
    textAlign: "left",
    marginLeft: 5,
  },
  eventText: {
    fontWeight: "700",
    fontSize: 14,
    textAlign: "left",
  },
  calendarText: {
    fontWeight: "700",
    fontSize: 12,
  },
  imagestyle: {
    resizeMode: "contain",
    height: 55,
    width: 55,
  },
  center: {
    justifyContent: "center",
    alignItems: "flex-start",
  },
  left: {
    justifyContent: "center",
    alignItems: "flex-start",
  },
});

export const eventItem = (guest, index, onPress) => {
  const waString = `${guest.wa_number}`;
  const waNumber = Number({ waString });
  const url = `https://wa.me/${guest.wa_number}?text=Saya%20mengundang%20Anda%20untuk%20mengikuti%20event%20berikut`;
  const isEvenRow = index % 2 === 0;
  const evenStyle = { backgroundColor: "#fff" };
  const containerStyle = [styles.container];
  if (isEvenRow) {
    containerStyle.push(evenStyle);
  }

  // const OpenURLButton = ({ url, children }) => {
  //   const handlePress = useCallback(async () => {
  //     // Checking if the link is supported for links with custom URL scheme.
  //     const supported = await Linking.canOpenURL(url);

  //     if (supported) {
  //       // Opening the link with some app, if the URL scheme is "http" the web link should be opened
  //       // by some browser in the mobile
  //       await Linking.openURL(url);
  //     } else {
  //       Alert.alert(`Don't know how to open this URL: ${url}`);
  //     }
  //   }, [url]);
  //   return <Button title={children} onPress={handlePress} />;
  // };

  const handlePress = () => {
    Linking.canOpenURL(url).then(() => {
      return Linking.openURL(url);
    });
  };

  let fullName = guest.first_name;
  if (guest.family_name != "") {
    fullName = fullName + " " + guest.family_name;
  }

  const qrCodeUrl =
    `/qrcode-generator` + "?guestId=" + guest.id + "&fullName=" + fullName;

  return (
    <View style={containerStyle} onPress={onPress}>
      <View style={[{ width: 30, marginLeft: 30 }, styles.center]}>
        <Text style={[styles.baseText, styles.eventText]}>{index + 1}</Text>
      </View>
      <View style={[{ width: 220, marginLeft: 30 }, styles.center]}>
        <Text style={[styles.baseText, styles.eventText]}>{fullName}</Text>
      </View>
      <View style={[{ flex: 0.9 }, styles.left]}>
        {/* <Text style={[styles.baseText, styles.eventText]}>
          +{guest.wa_number}
        </Text> */}
        {guest.wa_number === null ? (
          <Text style={[styles.baseText, styles.eventText]}></Text>
        ) : (
          <Text style={[styles.baseText, styles.eventText]}>
            {guest.wa_number}
          </Text>
        )}
      </View>
      <View style={[{ width: 250, textAlign: "left" }, styles.left]}>
        <Text style={[styles.baseText, styles.eventText]}>{guest.email}</Text>
      </View>
      <TouchableOpacity style={[{ flex: 0.9 }, styles.center]}>
        <Image
          source={require("../../../src/assets/wa-icon.png")}
          style={{ width: 20, height: 20 }}
          resizeMode="cover"
          onPress={(_) => handlePress(url)}
        />
      </TouchableOpacity>
      <a href={qrCodeUrl}>
        <View style={{ flex: 1 }}>
          <Button
            mode="contained"
            color="blue"
            style={{ width: 100, height: 50 }}
          >
            qr code
          </Button>
        </View>
      </a>
    </View>
  );
};
