import { firestore } from "./firebase";
import {
  collection,
  query,
  getDocs,
  addDoc,
  orderBy,
} from "firebase/firestore";
import { EVENTS } from "./event";

const database = firestore();
export const GUESTS = "guests";
export const getGuestsCollection = (eventId) => {
  const path = `${EVENTS}/${eventId}/${GUESTS}`;
  return collection(database, path);
};

export const guestBase = {
  data: [],
  limit: 10,
  page: 0,
};

export const getGuestsByEventId = async (
  eventId,
  page = 0,
  limit = 10,
  order = "asc"
) => {
  const q = query(getGuestsCollection(eventId), orderBy("created_at", order));
  const snapshot = await getDocs(q);

  const result = guestBase;
  result.data = [];
  result.page = page;
  result.limit = limit;

  if (snapshot.empty) {
    return result;
  }
  snapshot.forEach((doc) => {
    let data = doc.data();
    data.id = doc.id;
    result.data.push(data);
  });

  return result;
};

export const createGuestByEventId = async (eventId, data) => {
  const payload = {
    first_name: data.first_name,
    family_name: data.family_name,
    wa_number: data.wa_number,
    email: data.email,
    created_at: Math.floor(new Date() / 1000),
    modified_at: null,
  };

  const collection = getGuestsCollection(eventId);
  console.log(collection);
  const doc = await addDoc(collection, payload);
  console.log(doc.id);
  return doc;
};
