import React from "react";
import { Button, StyleSheet } from "react-native";

const BackButton = (props) => {
  let onPress = () => {};
  if ("history" in props) {
    onPress = () => props.history.goBack();
  }
  if ("onPress" in props) {
    onPress = props.onPress;
  }
  return <Button onPress={onPress} title="Back" style={styles.button} />;
};

const styles = StyleSheet.create({
  button: {
    width: 50,
  },
});

export default BackButton;
