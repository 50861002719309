// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB-ggqU_st0AOrE4576BNcqG0SeM_NNAdw",
  authDomain: "elinvi-brawn-app-dev.firebaseapp.com",
  projectId: "elinvi-brawn-app-dev",
  storageBucket: "elinvi-brawn-app-dev.appspot.com",
  messagingSenderId: "741420517065",
  appId: "1:741420517065:web:39c5b895a0140ca5ecef18",
  measurementId: "G-MVEX1MSLPZ",
};

// Initialize Firebase
const firebaseapp = initializeApp(firebaseConfig);
const mAnalytics = getAnalytics(firebaseapp);
const mFirestore = getFirestore(firebaseapp);

export const analytics = () => {
  return mAnalytics;
};

export const firestore = () => {
  return mFirestore;
};

export default firebaseapp;
