import React, { useState, useEffect } from "react";
import {
  Text,
  FlatList,
  View,
  Platform,
  StyleSheet,
  Linking,
  TouchableOpacity,
} from "react-native";
import { Button } from "react-native-paper";
import { getUser } from "../services/user";
import { getEventsByUser } from "../services/event";
// import { Link } from "react-router";
import dayjs from "dayjs";
import AddEvent from "./add-event";
import getGuestsByEventId from "../services/guest";
import { useQuery } from "../routing";
import Header from "../components/header";

const EventsPage = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]);

  const getData = async () => {
    try {
      await getUser(setUser, setError);
      const eventsResult = await getEventsByUser(0, 100);
      setEvents(eventsResult.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    return getData();
  }, []);

  const calendarWithDate = (date) => {
    const day = date.format("DD");
    const month = date.format("MMM");
    const year = date.format("YYYY");

    return (
      <View>
        <View>
          <Text style={[styles.baseText, styles.eventText]}>
            {day} {month} {year}
          </Text>
        </View>
      </View>
    );
  };

  const addGuestUrl = `/add-guest`;
  const viewGuestUrl = `/guest-list`;
  const query = useQuery();
  const guestId = query.get("guestId"); //belum berhasil

  const eventItem = ({ item, index }) => {
    const eventDate = dayjs.unix(item.event_date);
    const guestUrl = addGuestUrl + "?eventId=" + item.id;

    const viewGuestUrlbyEvent = viewGuestUrl + "?eventId=" + item.id;
    return (
      <View style={[styles.tableContainer]}>
        <View style={[styles.index, styles.baseText, styles.eventText]}>
          {index + 1}
        </View>
        <View style={styles.itemName}>
          <Text style={[styles.baseText, styles.eventText]}>{item.name}</Text>
        </View>
        <View style={styles.itemCity}>
          <Text style={[styles.baseText, styles.eventText]}>{item.city}</Text>
        </View>
        <View style={styles.itemDate}>{calendarWithDate(eventDate)}</View>
        <a href={viewGuestUrlbyEvent}>
          <View style={{ flex: 1 }}>
            <Button mode="contained" color="blue">
              lihat tamu
            </Button>
          </View>
        </a>
        <View style={{ width: 50 }} />
        <a href={guestUrl}>
          <View style={{ flex: 1 }}>
            <Button mode="contained" color="blue">
              tambah tamu
            </Button>
          </View>
        </a>
        <View style={{ width: 50 }} />
      </View>
    );
  };

  const eventList = (
    <FlatList
      data={events}
      style={styles.tableContainer}
      renderItem={eventItem}
      keyExtractor={(item) => item.id}
      ItemSeparatorComponent={() => {
        return <View style={styles.space} />;
      }}
    />
  );

  const addEventUrl = `/add-event`;

  return (
    <View style={styles.fullHeight}>
      <Header />
      <View style={styles.space} />
      <View style={{ marginLeft: 30 }}>
        <View>
          <Text style={styles.pageTitle}>List Event</Text>
        </View>
        <View style={{ height: 20 }} />
        <View style={styles.tableContainer}>
          <Text style={[{ flex: 0.7 }, styles.tableTitleText]}>No</Text>
          <Text style={[{ flex: 2 }, styles.tableTitleText]}>Nama Event</Text>
          <Text style={[{ flex: 1.2 }, styles.tableTitleText]}>Kota</Text>
          <Text style={[{ flex: 1 }, styles.tableTitleText]}>Waktu</Text>
          <View style={{ flex: 2.25 }} />
        </View>
        <View style={styles.space} />
        {eventList}
        <View style={{ width: 1000, height: 150 }} />
        <a href={addEventUrl}>
          <View style={{ width: 150 }}>
            <View style={styles.space} />
            <Button mode="contained" color="blue">
              tambah event
            </Button>
          </View>
        </a>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  fullHeight: {
    height: Platform.OS === "web" ? "100vh" : "100%",
    // marginLeft: 50,
  },
  tableContainer: {
    width: 1200,
    flexDirection: "row",
    marginLeft: 10,
  },
  item: {
    backgroundColor: "#f9c2ff",
    padding: 5,
    marginVertical: 8,
    marginHorizontal: 16,
  },
  title: {
    fontSize: 32,
    textAlign: "center",
  },
  container: {
    width: 1000,
    flexDirection: "row",
  },
  index: {
    flex: 0.6,
  },
  itemName: {
    flex: 2,
  },
  itemCity: {
    flex: 1.2,
  },
  itemDate: {
    flex: 1,
  },
  textContainer: {
    width: 500,
    marginTop: 20,
    flexDirection: "row",
    alignContent: "center",
  },
  textStyle: {
    fontSize: 20,
  },
  pageTitle: {
    fontSize: 40,
    fontWeight: "500",
  },
  input: {
    height: 40,
    width: 300,
    margin: 3,
    borderWidth: 1,
    padding: 10,
    alignSelf: "center",
    alignContent: "center",
  },
  space: {
    width: 1000,
    height: 20,
  },
  baseText: {
    color: "#000000",
    textAlign: "left",
  },
  eventText: {
    fontWeight: "700",
    fontSize: 16,
  },
  tableTitleText: {
    fontWeight: "500",
    fontSize: 20,
  },
});

export default EventsPage;
