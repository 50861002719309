import React, { useState, useEffect } from "react";
import {
  SafeAreaView,
  View,
  StyleSheet,
  StatusBar,
  Button,
} from "react-native";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { getUser, googleSignIn, signOut } from "../services/user";
import { useHistory, useQuery } from "../routing";
import firebaseapp from "../services/firebase";

const currentUser = (setUser, setError) => {
  console.log("currentUser called");
  try {
    console.log("getAuth called");
    const auth = getAuth();
    const userInfo = auth.currentUser;
    console.log("userInfo", userInfo);
    if (userInfo !== null) {
      return setUser(userInfo);
    }
  } catch (error) {
    console.log("error called", error);
    return setError(error);
  }
};

const App = () => {
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    return currentUser(setUser, setError);
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <View style={{ width: 100, height: 50, marginLeft: 25 }}>
        {user === null ? (
          <Button
            onPress={(_) => googleSignIn((user)=>{
              setUser(user);
              history.replace("/")
            }, setError)}
            title="Login"
          />
        ) : (
          <Button onPress={(_) => signOut(setUser, setError)} title="SignOut" />
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 1000,
    marginTop: StatusBar.currentHeight || 0,
  },
  item: {
    backgroundColor: "#f9c2ff",
    padding: 5,
    marginVertical: 8,
    marginHorizontal: 16,
  },
  title: {
    fontSize: 32,
    textAlign: "center",
  },
  space: {
    width: 50, // or whatever size you need
    height: 20,
  },
  listWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginLeft: 10,
  },
  rowText: {
    color: "#000",
    width: 250,
    fontSize: 20,
    padding: 5,
  },
  numberText: {
    color: "#000",
    flex: 1,
    marginLeft: 20,
    fontSize: 20,
    padding: 5,
  },
});

export default App;
